// meyer reset
html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}body{line-height:1}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:before,blockquote:after,q:before,q:after{content:'';content:none}table{border-collapse:collapse;border-spacing:0}

// fonts (for reference, actually imported over HTML beacause of performance)
// @import url('https://fonts.googleapis.com/css?family=Eczar:400,800|Work+Sans:500');

// actual css
:root {
  --c-blue: #a2dfff;
  --c-salmon: #f8c1ac;
  --c-mint: #8dffcd;
  --c-wine: #814157;
  --c-yellow: #fffd4cfa;
  --c-white: #fff;

  --color-background: var(--c-wine);
  --color-background--contrast: var(--c-white);
  --color-highlight: #ff6c6c;
}

::selection {
  background: var(--color-highlight);
  color: #fff;
}

.is-hidden {
  display: none;
}

html {
  font-size: calc(1.55vw + 0.4rem);
  font-family: 'Work Sans', sans-serif;
  background: var(--color-background);

  @media all and (max-width: 736px) {
    font-size: calc(1.55vw + .55rem);
  }
}

a {
  color: darken(#ff6c6c, 10%);
  text-decoration: none;
  display: inline-block;
  padding: 0.1em 0.35em;
  margin: -0.1em -0.35em;

  &:hover {
    background: var(--color-highlight);
    color: #fff;
  }
}

h2,
h3 {
  font-family: 'Eczar', serif;
  font-weight: 800;
}

strong {
  font-family: 'Eczar', serif;
  font-weight: 800;
}

blockquote {
  font-family: 'Eczar', serif;
  font-weight: 800;
  font-size: 3.5rem;
  margin: 0.5em 0;
}

.quote-section {
  position: relative;
}

.quote-section__fake {
  visibility: hidden;
}

.typed-cursor {
  color: #fff;
}

.quote-section__text {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 0;
}

.page-header {
  display: flex;
}

.logo {
  text-align: center;
  font-size: 1.2rem;
  line-height: 0.72;
  display: flex;
  align-items: flex-end;
}

.logo svg {
  //overflow: visible;
  display: block;
  width: 4vmax;
  height: 4vmax;
  fill: none;
  stroke: var(--color-background);
  stroke-width: 9;
  background: #ff6c6c;
  stroke-dasharray: 150 150;
  //stroke-dashoffset: 150;
  transition: 300ms stroke-dashoffset ease-in-out;
  margin-right: 0.5rem;

  &:hover {
    stroke-dashoffset: 0;

  }
}

.services {
  font-family: 'Eczar', serif;
  font-weight: 800;
  font-size: 2rem;
  line-height: 1.1;
  margin: calc(1.7vw + 0.3rem) 0 0;
  color: #000;

  @media all and (max-width: 736px) {
    p {
      padding: 1rem 0;
    }
  }

  strong {
    color: #ff6c6c;
  }
}

.section {
  padding: calc(7.55vw + 0.4rem) 0;
  background: var(--color-background--contrast);
  @media all and (max-width: 736px) {
    padding: 1rem 0;
  }
}

.intro {
  background: var(--color-background);
  color: var(--color-background--contrast);
  padding-bottom: 0;
}

.design-width {
  max-width: 36rem;
  margin: 0 auto;
  padding: 0 1rem;
}

.contacts {
  padding-top: 1rem;
  padding-bottom: 2.5rem;
  line-height: 1.3;
  background: var(--color-background--contrast);
}

.contact {
  display: flex;
  flex: 0 0 50%;
  padding-top: 1.5rem;

  span {
    display: inline-block;
    width: 5rem;

    @media all and (max-width: 736px) {
      width: auto;
      font-weight: bold;
      margin-right: 0.2em;

      &::after {
        content: ':';
      }
    }
  }

  h2 {
    font-size: 2em;
    margin-bottom: 0.25em;
  }

  @media all and (max-width: 736px) {
    display: block;
    text-align: center;
  }
}

.contact__img {
  flex: 0 1 25%;

  img {
    margin-top: -0.7rem;
    width: 100%;
    transform: translateX(-10%);

    @media all and (max-width: 736px) {
      transform: none;
      width: 80%;
      margin-top: 0;
      margin-bottom: 1rem;
    }
  }
}

.footer {
  padding-top: 3rem;
  padding-bottom: 1rem;
  background: var(--color-background);
  color: var(--color-background--contrast);
  line-height: 1.4;
  background-image: url(./assets/exalt-all.png);
  background-size: 25% auto;
  background-repeat: no-repeat;
  background-position: 90% bottom;
  background-attachment: fixed;
}

.exalt-all {
  display: block;
  width: 100%;
  margin-bottom: -1rem;
}

#quotes {
  display: none;
}